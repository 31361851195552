import {createStore, applyMiddleware, combineReducers} from 'redux'
import {createLogger} from "redux-logger";
import {
    authReducer,
    supplierReducer,
    coworkerReducer,
    shopReducer,
    assignmentDescriptionReducer,
    assignmentReducer,
    userFeedbackReducer
} from "../reducers";

const logger = createLogger();
const enhancer = applyMiddleware(logger);

const combinedReducers = combineReducers({
    auth: authReducer,
    supplier: supplierReducer,
    coworker: coworkerReducer,
    shop: shopReducer,
    assignmentDescription: assignmentDescriptionReducer,
    assignment: assignmentReducer,
    userFeedback: userFeedbackReducer,
});

const store = createStore(combinedReducers, enhancer);

export default store;