import React, {Component} from 'react';
import {Select as AntSelect} from 'antd';
import {FormattedHTMLMessage} from 'react-intl';

const Option = AntSelect.Option;

export default class Select extends Component {
    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
    }

    handleFocus() {
        console.log('focus');
    }

    handleBlur() {
        console.log('blur');
    }

    getPlaceholder = () => {
        if(this.props.placeholderLabelKey){
            return <FormattedHTMLMessage id={this.props.placeholderLabelKey}/>
        } else {
            return <FormattedHTMLMessage id="select"/>
        }
    };

    render() {
        return (
            <div>
                <label>
                    <FormattedHTMLMessage id={this.props.labelKey}/>
                </label>
                <AntSelect
                    showSearch
                    style={{width: 200}}
                    placeholder={this.getPlaceholder()}
                    optionFilterProp="children"
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    {...this.props}
                >
                    {this.props.selectOptions.map(option => (
                        <Option key={option.value} value={option.value}>{option.text}</Option>
                    ))}

                </AntSelect>
            </div>
        );
    }
}
