import {ASSIGNMENT_ACTION_TYPE} from './../actions/constants'

const initialState = {
    assignments: [], //TODO: Break out to common?
    selectedAssignment: null,
};

const assignmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGNMENT_ACTION_TYPE.ASSIGNMENT_RETRIEVED: {
            const {assignments} = action.payload;
            return {...state, assignments}
        }
        case ASSIGNMENT_ACTION_TYPE.ASSIGNMENT_SELECTED: {
            const {assignment} = action.payload;
            return {...state, selectedAssignment: assignment}
        }
        case ASSIGNMENT_ACTION_TYPE.CLEAR_ASSIGNMENT: {
            return {...state, selectedAssignment: null}
        }
    }
    return state;
};

export default assignmentReducer;