import React, {Component} from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import './index.css';
import {Input} from 'antd';

export default class LabeledInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});

        //Pass the event up to the form
        this.props.handleChange(event);

        console.log(`Change - Component: ${event.target.name}, Value: ${event.target.value}`)
    }

    render() {
        const {labelKey, prefix, ...standardProps} = this.props;
        return (
            <div>
                <div>
                    {labelKey &&
                    <label>
                        <FormattedHTMLMessage id={labelKey}/>
                    </label>
                    }
                    <Input prefix={prefix} {...standardProps}/>
                </div>
            </div>
        );
    }
}
