import fetch from './../lib/fetch-client'

const request = fetch();

export function getCoworker() {
    return request({
        method: "GET",
        path: "coworker",
    }).then(function({ status, headers, body }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        
        return body;
    });
}

export function createCoworker({ firstName, email, confirmEmail, cellphoneNumber, confirmCellphoneNumber }) {
    return request({
        method: "POST",
        path: "coworker/Create",
        params: {
            firstName,
            email,
            confirmEmail,
            mobilePhoneNumber: cellphoneNumber,
            confirmMobilePhoneNumber: confirmCellphoneNumber
        }
    }).then(function({ status, headers, body: coworkers }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        
        return coworkers;
    });
}

export function updateCoworker({ key, firstName, email, confirmEmail, cellphoneNumber, confirmCellphoneNumber }) {
    return request({
        method: "PATCH",
        path: "coworker/Update",
        params: {
            key,
            firstName,
            email,
            confirmEmail,
            mobilePhoneNumber: cellphoneNumber,
            confirmMobilePhoneNumber: confirmCellphoneNumber
        },
    }).then(function({ status, headers, body: coworkers }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }
        
        return coworkers;
    });
}

export function deleteCoworker(coworkerId) {
    return request({
        method: "DELETE",
        path: "coworker/Delete?id="+coworkerId,
    }).then(function({ status, headers, body: coworkers }) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return coworkers;
    });
}