import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedHTMLMessage, FormattedMessage, IntlProvider} from 'react-intl';
import {Form, Modal} from 'antd';
import {Row, Col} from 'react-bootstrap';
import ReactSVG from 'react-svg';
import languages from '../../intl'

import styles from './../login/LoginPage.module.scss';
import {
    apiCallFinished,
    apiCallStarted,
    finalizeAccountInfoRetrieved,
    loginInitiated,
    loginSuccessful
} from "../../actions";
import CoworkerForm from "../coworkers/coworker-form";
import {getFinalizeAccountInfo, finalizeAccountInfo, login} from "../../Api";
import {errorReceived} from "../../actions/user-feedback";

const WrappedCoworkerForm = Form.create({name: 'coworkerForm'})(CoworkerForm);

class FinalizeAccountPage extends Component {
    state = {
        showTokenExpired: false,
        showConfirmEmail: false
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const {finalizeAccountToken} = this.props.match.params;

        let finalizeAccountInfoResult;

        try {
            this.props.onApiCallStarted();
            finalizeAccountInfoResult = await getFinalizeAccountInfo(finalizeAccountToken);
            this.props.onApiCallFinished();

            this.props.onFinalizeAccountInfoRetrieved(finalizeAccountInfoResult);
            if (this.form !== undefined) {
                this.form.resetFields();
                this.form.validateFields();
            }
        } catch (e) {
            this.showTokenExpiredModal();
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    showAccountFinalizedModal() {
        this.props.onApiCallFinished();
        this.setState({showConfirmEmail: true});

        Modal.info({
            title: <IntlProvider locale="sv" messages={languages.sv}><FormattedMessage
                id="finalizedAccountTitle"/></IntlProvider>,
            content: (
                <IntlProvider locale="sv" messages={languages.sv}>
                    <div>
                        <FormattedMessage id="finalizedAccountText"/>
                    </div>
                </IntlProvider>
            ),
            onOk() {
                window.close();
            },
        });
    }

    showTokenExpiredModal() {
        this.props.onApiCallFinished();
        this.setState({showTokenExpired: true});

        Modal.warning({
            title: <IntlProvider locale="sv" messages={languages.sv}><FormattedMessage
                id="finalizeTokenExpiredTitle"/></IntlProvider>,
            content: (
                <IntlProvider locale="sv" messages={languages.sv}>
                    <div>
                        <FormattedMessage id="finalizeTokenExpiredText"/>
                    </div>
                </IntlProvider>
            ),
            onOk() {
                window.close();
            },
        });
    }

    handleFormSubmit = async (e, form) => {
        e.preventDefault();

        let userData = null;
        form.validateFields((err, values) => {
            if (!err) {

                if (values.email.toLowerCase() !== values.confirmEmail.toLowerCase()) {
                    this.form.setFields({
                        confirmEmail: {
                            value: values.confirmEmail,
                            errors: [new Error('E-mail och bekräftat e-mail stämmer inte.')],
                        }
                    });

                    err = true;
                }

                if (values.cellphoneNumber !== values.confirmCellphoneNumber) {
                    this.form.setFields({
                        confirmCellphoneNumber: {
                            value: values.confirmCellphoneNumber,
                            errors: [new Error('Mobilnummer och bekräftat mobilnummer stämmer inte.')],
                        }
                    });

                    err = true;
                }

                if (!err) {
                    userData = values;
                }
            }
        });

        if (userData != null) {
            try {
                let token = this.props.finalizeAccountInfo.finalizeAccountToken;

                this.props.onApiCallStarted();
                let finalizeAccount = await finalizeAccountInfo({token, ...userData});
                this.props.onApiCallFinished();

                this.showAccountFinalizedModal();
            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    setFormRef = (form) => {
        this.form = form;
    };

    renderContent() {
        let pageContent;

        if (this.state.showConfirmEmail) {
            pageContent = (
                <Col xs={9} className="mx-auto">
                    <h1 className={styles.heading}>
                        <FormattedHTMLMessage id="finalizedAccountTitle"/>
                    </h1>
                    <div>
                        <FormattedMessage id="finalizedAccountText"/>
                    </div>
                </Col>);
        } else if (this.state.showTokenExpired) {
            pageContent = (
                <Col xs={9} className="mx-auto">
                    <h1 className={styles.heading}>
                        <FormattedHTMLMessage id="finalizeTokenExpiredTitle"/>
                    </h1>
                    <div>
                        <FormattedMessage id="finalizeTokenExpiredText"/>
                    </div>
                </Col>);
        } else {
            pageContent = (
                <Col xs={9} className="mx-auto">
                    <h1 className={styles.heading}>
                        <FormattedHTMLMessage id="finalizeYourAccount"/>
                    </h1>
                    <WrappedCoworkerForm ref={this.setFormRef} onFormPosted={this.handleFormSubmit}
                                         isFinalizeAccount={true}
                        finalizeAccountToken={this.props.match.params.finalizeAccountToken} />
                    <i><FormattedHTMLMessage id="consentInformation" /></i>
                </Col>);
        }

        return (
            <Row>
                <Col xs={8} className="mx-auto">
                    <Row className={styles.head}>
                        <ReactSVG src="/img/logotype.svg" className="m-auto"/>
                    </Row>
                    <Row className={styles.form}>
                        {pageContent}
                    </Row>
                </Col>
            </Row>);
    }

    render() {
        return (
            <div>
                {this.renderContent()}
            </div>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({
    auth: auth,
    finalizeAccountInfo: auth.finalizeAccountInfo
});

const mapDispatchToActions = {
    onLoginInitiated: loginInitiated,
    onLoginSuccessful: loginSuccessful,
    onFinalizeAccountInfoRetrieved: finalizeAccountInfoRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(FinalizeAccountPage);