import fetch from './../lib/fetch-client'
import Config from './../config'

const conf = new Config();
const request = fetch();

export function login({userId, password, rememberMe}) {
    return request({
        method: "POST",
        path: "account/login",
        params: {
            userId, password, rememberMe
        },
    }).then(function ({status, headers, body}) {
        const {user, jwt} = body;
        if (status === 200) {
            conf.jwtToken = jwt.token;
            return {user, jwt};
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function forgotPassword(email) {
    return request({
        method: "POST",
        path: "account/ForgotPassword",
        params: {
            email: email
        },
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return true;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function resetPassword({email, password, confirmPassword, code}) {
    return request({
        method: "POST",
        path: "account/ResetPassword",
        params: {
            email, 
            password, 
            confirmPassword, 
            code
        },
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return true;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function logOut() {
    return request({
        method: "POST",
        path: "account/Logout",
    }).then(function ({status}) {
        if (status === 200) {
            return true;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function getFinalizeAccountInfo(finalizeAccountToken) {
    return request({
        method: "GET",
        path: `account/finalizeAccount/?finalizeAccountToken=${finalizeAccountToken}`,
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return body;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}

export function finalizeAccountInfo(data) {
    const {
        token: finalizeAccountToken,
        firstName,
        lastName,
        email,
        confirmEmail,
        password,
        confirmPassword,
        cellphoneNumber: mobilePhoneNumber,
        confirmCellphoneNumber: confirmMobilePhoneNumber
    }
        = data;

    return request({
        method: "POST",
        path: `account/finalizeAccount`,
        params: {
            finalizeAccountToken,
            firstName,
            lastName,
            email,
            confirmEmail,
            password,
            confirmPassword,
            mobilePhoneNumber,
            confirmMobilePhoneNumber
        },
    }).then(function ({status, headers, body}) {
        if (status === 200) {
            return body;
        }

        throw new Error(`Invalid status code. Status: ${status}`);
    });
}