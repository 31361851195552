import React from 'react';
import {connect} from "react-redux";
import Highlighter from 'react-highlight-words';
import {FormattedMessage} from "react-intl";
import {
    Table, Input, Button, Icon, Modal, Form, Avatar,
} from 'antd';
import {coworkerSelected} from "../../actions";
import CoworkerForm from "./coworker-form";
import ShowListItem from "../../components/show-list-item/ShowListItem";

const WrappedCoworkerForm = Form.create({name: 'coworkerForm'})(CoworkerForm);


class CoworkerTable extends React.Component {
    state = {
        searchText: '',
        selectedElement: ''
    };

    handleCancel = (e) => {
        console.log(e);
        this.closeModal()
    };

    modalTitle = () => {
        if (this.isEditMode()) {
            return <FormattedMessage id="editCoworker"/>
        } else {
            return <FormattedMessage id="newCoworker"/>
        }
    };

    closeModal = () => {
        this.props.onCoworkerSelected(null);
        this.setState({
            visible: false,
        });
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    onPrevious = () => {
        const {coworkers} = this.props.coworker;

        let newIndex = this.sate.currentIndex - 1;
        if (newIndex < 0) {
            newIndex = coworkers.length - 1;
        }

        this.sate = {...this.sate, currentIndex: newIndex};

        let prevRecord = coworkers[newIndex];
        this.props.onCoworkerSelected(prevRecord);
    };

    onNext = () => {
        const {coworkers} = this.props.coworker;

        let newIndex = this.sate.currentIndex + 1;
        if (newIndex > coworkers.length - 1) {
            newIndex = 0;
        }

        this.sate = {...this.sate, currentIndex: newIndex};
        let nextRecord = coworkers[newIndex];
        this.props.onCoworkerSelected(nextRecord);
    };

    isEditMode = () => {
        return true;
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys, selectedKeys, confirm, clearFilters,
                         }) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder="Sök"
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Sök
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{width: 90}}
                >
                    Nollställ
                </Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({searchText: selectedKeys[0]});
    };

    itemSelected = (event, record, rowIndex) => {
        if (this.form !== undefined) {
            this.form.resetFields();
        }

        this.sate = {...this.sate, currentIndex: rowIndex};
        this.setState({selectedElement: record});
        this.props.onCoworkerSelected(record)
    };

    setFormRef = (form) => {
        this.form = form;
    };

    clearSelectedItem = () => {
        this.setState({selectedElement: ''});
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText: ''});
    };

    handleFormPosted = async (e, form, isEditMode) => {
        this.props.onFormPosted(e, form, isEditMode, this.closeModal);
    };


    render() {
        const columns = [{
            dataIndex: 'avatarPath',
            key: 'avatarPath',
            render: (text, record) => {
                if (record.avatarPath) {
                    return <Avatar src={record.avatarPath}/>
                }

                if (record.requiresAvatar)
                    return <Avatar icon="user" style={{backgroundColor: '#ffa700'}}/>;
                
                return <Avatar icon="user"/>;
            }
        }, {
            title: <FormattedMessage id="name"/>,
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...this.getColumnSearchProps('name'),
            sorter: (a, b) => ('' + a.name).localeCompare(b.name),
            sortDirections: ['descend', 'ascend']
        }, {
            title: <FormattedMessage id="email"/>,
            dataIndex: 'email',
            key: 'email',
            width: '30%',
            ...this.getColumnSearchProps('email'),
            sorter: (a, b) => ('' + a.email).localeCompare(b.email),
            sortDirections: ['descend', 'ascend']
        }, {
            title: <FormattedMessage id="mobilePhoneNumber"/>,
            dataIndex: 'mobilePhoneNumber',
            key: 'mobilePhoneNumber',
            width: '30%',
            ...this.getColumnSearchProps('mobilePhoneNumber'),
            sorter: (a, b) => ('' + a.mobilePhoneNumber).localeCompare(b.mobilePhoneNumber),
            sortDirections: ['descend', 'ascend']
        }, {
            title: <FormattedMessage id="added"/>,
            dataIndex: 'added',
            key: 'added',
            width: '30%',
            ...this.getColumnSearchProps('added'),
            sorter: (a, b) => ('' + a.added).localeCompare(b.added),
            sortDirections: ['descend', 'ascend']
        }, {
            title: <FormattedMessage id="emailConfirmed" />,
            dataIndex: 'emailConfirmed',
            key: 'emailConfirmed',
            width: '30%',
            ...this.getColumnSearchProps('emailConfirmed'),
            sorter: (a, b) => ('' + a.emailConfirmed).localeCompare(b.emailConfirmed),
            sortDirections: ['descend', 'ascend']
        }];

        const {dataSource, ...remainingProps} = this.props;
        const {selectedCoworker} = this.props.coworker;
        const {onSubmit} = this.props.coworker;
        return (
            <div>
                <Modal
                    footer={null}
                    title={this.modalTitle()}
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                >
                    <WrappedCoworkerForm ref={this.setFormRef} coworker={selectedCoworker} isEditMode={true}
                                         closeModal={this.handleCancel} onFormPosted={this.handleFormPosted}
                                         onSubmit={onSubmit}/>
                </Modal>
                <Table columns={columns} dataSource={this.props.coworker.coworkers} {...remainingProps}
                       onRow={(record, rowIndex) => {
                           return {
                               onClick: (event) => {
                                   this.itemSelected(event, record, rowIndex);
                               }, // click row
                               onDoubleClick: (event) => {
                               }, // double click row
                               onContextMenu: (event) => {
                               }, // right button click row
                               onMouseEnter: (event) => {
                               }, // mouse enter row
                               onMouseLeave: (event) => {
                               }, // mouse leave row
                           };
                       }}/>

                {selectedCoworker &&
                <ShowListItem
                    heading={selectedCoworker.name}
                    onEdit={this.showModal}
                    onClose={this.closeModal}
                    onNext={this.onNext}
                    onPrevious={this.onPrevious}
                    rows={[
                        [
                            {
                                label: 'E-post',
                                value: selectedCoworker.email
                            },
                            {
                                label: 'Tillagd',
                                value: selectedCoworker.added
                            }
                        ],
                        [
                            {
                                label: 'Telefonnummer',
                                value: selectedCoworker.mobilePhoneNumber
                            }
                        ]
                    ]}
                />
                }
            </div>
        );
    }
}

const mapStateToProps = ({coworker}) => ({coworker});

const mapDispatchToActions = {
    onCoworkerSelected: coworkerSelected
};

export default connect(mapStateToProps, mapDispatchToActions)(CoworkerTable);