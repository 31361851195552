import {COWORKER_ACTION_TYPE} from './../actions/constants'

const initialState = {
    coworkers: [], //TODO: Break out to common?
    selectedCoworker: null
};

const coworkerReducer = (state = initialState, action) => {
    switch (action.type) {
        case COWORKER_ACTION_TYPE.COWORKERS_RETRIEVED: {
            const {coworkers} = action.payload;
            return {...state, coworkers}
        }
        case COWORKER_ACTION_TYPE.COWORKER_SELECTED: {
            const {coworker} = action.payload;
            return {...state, selectedCoworker: coworker}
        }
        case COWORKER_ACTION_TYPE.CLEAR_COWORKER: {
            return {...state, selectedCoworker: null}
        }
    }
    return state;
};

export default coworkerReducer;