import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Row, Col} from 'antd';
import {FormattedMessage} from 'react-intl';
import styles from './ShowListItem.module.scss';

export default class ShowListItem extends PureComponent {
    static propTypes = {
        heading: PropTypes.string.isRequired,
        onPrevious: PropTypes.string.isRequired,
        onNext: PropTypes.string.isRequired,
        onEdit: PropTypes.func,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const {heading, onEdit, onClose, onPrevious, onNext, rows, children} = this.props;
        return (
            <div className={styles.container}>
                <div className="container">
                    <Row>
                        <Col span={12} className={styles.name}>
                            <h3>{heading}</h3>
                        </Col>

                        <Col span={12} className="text-right">
                            {onEdit &&
                            <Button size="large" type="primary" onClick={onEdit} className={styles.editButton}>
                                <FormattedMessage id="edit"/>
                            </Button>
                            }
                        </Col>
                    </Row>

                    {rows ? rows.map((row, index) => (
                        <Row key={'row-' + index} className={styles.row}>
                            {row.map((col) => (
                                <Col key={'col-' + index} span={12}>
                                    <div className={styles.label}>{col.label}</div>
                                    <div className={styles.value}>{col.value}</div>
                                </Col>
                            ))}
                        </Row>
                    )) : children}


                    {onClose && <Icon type="close" onClick={onClose} className={[styles.action, styles.close]}/>}
                    {onPrevious &&
                    <Icon type="arrow-left" onClick={onPrevious} className={[styles.action, styles.prev]}/>}
                    {onNext && <Icon type="arrow-right" onClick={onNext} className={[styles.action, styles.next]}/>}
                </div>
            </div>
        );
    }
}