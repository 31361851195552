import React, {Component} from 'react';
import {connect} from "react-redux";
import {errorReceived} from "../../actions/user-feedback";
import {Container} from "react-bootstrap";
import {Icon, Spin} from "antd";
import ErrorModal from "../error-modal";

class UserFeedback extends Component {
    render() {
        const antIcon = <Icon type="sync" style={{fontSize: "7rem"}} spin/>;
        const pageContent = this.props.children;

        return (
            <Container>
                {this.props.errors && <ErrorModal/>}
                <Spin indicator={antIcon} spinning={this.props.callingApi && !this.props.errors} size="large">
                    {pageContent}
                </Spin>
            </Container>
        );
    }
}

const mapStateToProps = ({userFeedback}) => ({
    callingApi: userFeedback.callingApi,
    errors: userFeedback.errors
});

const mapDispatchToActions = {
    onErrorReceived: errorReceived,
};

export default connect(mapStateToProps, mapDispatchToActions)(UserFeedback);