import React, { Component } from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
  IntlProvider,
} from "react-intl";
import { Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { notification, Button } from "antd";
import { showNotification, hideNotification } from "../actions/user-feedback";
import languages from "../intl";
import NavMenu from "./nav-menu";
import UserFeedback from "./user-feedback";

const EXTERNAL_URL =
  "https://docs.google.com/document/d/1hjJ50SHQE2TW_4dIJaKZuJE3XFvsqdIo98blX76fkQ4";

class Layout extends Component {
  displayName = Layout.name;

  openNotification(onClose) {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          const win = window.open(EXTERNAL_URL, "_blank");
          if (win != null) {
            win.focus();
          }
        }}
      >
        <IntlProvider locale="sv" messages={languages.sv}>
          <FormattedMessage id="readMore" />
        </IntlProvider>
      </Button>
    );

    notification.info({
      message: (
        <IntlProvider locale="sv" messages={languages.sv}>
          <FormattedMessage id="appInformationTitle" />
        </IntlProvider>
      ),
      description: (
        <IntlProvider locale="sv" messages={languages.sv}>
          <FormattedMessage id="appInformationDescription" />
        </IntlProvider>
      ),
      placement: "topRight",
      duration: 0,
      top: 100,
      btn,
      onClose,
    });
  }

  componentDidMount() {
    if (
      this.props.auth.accessCoWorkers &&
      !this.props.userFeedback.isShowingNotification &&
      !this.props.userFeedback.dismissedNotification
    ) {
      this.props.showNotificationAction();
      this.openNotification(this.props.hideNotificationAction);
    }
  }

  render() {
    return (
      <Container fluid>
        <UserFeedback>
          <Row>
            <NavMenu />
          </Row>
          <Row>{this.props.children}</Row>
        </UserFeedback>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, userFeedback }) => ({ auth, userFeedback });
const mapDispatchToProps = {
  showNotificationAction: showNotification,
  hideNotificationAction: hideNotification,
};
const connectedLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);

export { connectedLayout as Layout };
