import {COWORKER_ACTION_TYPE} from './constants'


export const coworkersRetrieved = (coworkers) => ({
    type: COWORKER_ACTION_TYPE.COWORKERS_RETRIEVED,
    payload: {
        coworkers
    }
});

export const coworkerSelected = (coworker) => ({
    type: COWORKER_ACTION_TYPE.COWORKER_SELECTED,
    payload: {
        coworker
    }
});

export const clearCoworkers = () => ({
    type: COWORKER_ACTION_TYPE.CLEAR_COWORKER,
    payload: {}
});