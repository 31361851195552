import fetch from './../lib/fetch-client'

const request = fetch();

export function getAssignmentDescriptions() {
    return request({
        method: "GET",
        path: "assignmentDescription",
    }).then(function ({status, headers, body}) {
        return body;
    });
}