import {
    __,
    head,
    compose,
    merge,
    ifElse,
    always,
    toLower,
    contains,
} from 'ramda'
import HttpError from 'standard-http-error'
import createLogger from './logger'
import Config from '../../config'

const conf = new Config();

const TIMEOUT = 90000;
const BODY_METHODS = ['patch', 'post', 'put'];
const DEFAULT_HEADER = {
    Accept: 'application/json',
};


// castArray :: *... -> [*]
// Inspired by https://github.com/lodash/lodash/blob/ef2c4bf/castArray.js
//Remove export
export const castArray = (...args) => {
    if (!args.length) return [];

    const value = head(args);
    return Array.isArray(value) ? value : [value]
};

// parseRequestBody :: Object -> JSON
const parseRequestBody = JSON.stringify.bind(JSON);

// parseResponseBody :: JSON -> Object
const parseResponseBody = JSON.parse.bind(JSON);

// isBodyMethod :: String -> Boolean
const isBodyMethod = compose(contains(__, BODY_METHODS), toLower);

// headers :: Method -> Object
const createHeaders = compose(
    merge(DEFAULT_HEADER),
    ifElse(
        isBodyMethod,
        always({
            'Content-Type': 'application/json'
        }),
        always()
    )
);

/**
 * Rejects a promise after `ms` milliseconds if it's still pending.
 *
 * @param {Promise} promise
 * @param {number} ms
 * @returns {Promise}
 */
function timeout(promise, ms) {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => reject(new HttpError(408)), ms);
        promise
            .then(response => {
                clearTimeout(timer);
                resolve(response)
            })
            .catch(reject)
    })
}

function performRequest(method, path, headers, body) {
    try {
        const options = body
            ? {method, headers, body: parseRequestBody(body)}
            : {method, headers};

        return timeout(fetch(path, options), TIMEOUT)
    } catch (e) {
        throw new Error(e)
    }
}

// try to get the best possible error message out of a response
// without throwing errors while parsing
async function getErrorMessageSafely(response) {
    try {
        const body = await response.text();

        if (!body) {
            return ''
        }

        // Optimal case is JSON with a defined message property
        const payload = JSON.parse(body);

        /**
         * TODO: The api will return an array of errors located on payload.errors
         *       atm we ignore that property and miss out on theese error messages.
         */

        if (payload && payload.message) {
            return payload.errors
                ? [payload.message, payload.errors]
                : payload.message
        }

        // Should that fail, return the whole response body as text
        return body
    } catch (e) {
        // Unreadable body, return whatever the server returned
        return response._bodyInit
    }
}

/**
 * type Options = {
 *   logger?: {
 *    enabled: boolean,
 *    name: string
 *   }
 * }
 */
export default function createFetchClient(options) {
    const config = merge({
        logger: {
            enabled: true,
            name: 'fetch'
        }
    }, options);

    const logger = config.logger.enabled && createLogger(config.logger);

    async function handleResponse(method, path, response) {
        try {
            const status = response.status;

            // `fetch` promises resolve even if HTTP status indicates failure. Reroute
            // promise flow control to interpret error responses as failures
            if (status >= 400) {
                const errorResponse = await getErrorMessageSafely(response);
                const [message, extra] = castArray(errorResponse);
                const error = new HttpError(status, message, extra);

                throw error
            }

            // parse response text
            const responseBody = await response.text();

            const res = {
                status: response.status,
                headers: response.headers,
                body: responseBody ? parseResponseBody(responseBody) : null
            };

            if (config.logger.enabled) {
                logger.response(res, method, path)
            }

            return res
        } catch (e) {
            throw e
        }
    }

    /**
     * type RequestParams = {
     *   name: string,
     *   method: string,
     *   path: string,
     *   params?: Object,
     *   headers?: Object,
     *   options?: Object
     * }
     */
    async function request(requestParams) {
        const {
            method,
            path,
            params,
            headers = {}
        } = requestParams;

        let mergedHeaders = merge(headers, createHeaders(method));

        //Add token
        if (conf.jwtToken !== "") {
            mergedHeaders = merge(mergedHeaders, {'Authorization': "Bearer " + conf.jwtToken});
        }

        if (config.logger.enabled) {
            logger.request(method, path, params, mergedHeaders)
        }

        try {
            const response = await performRequest(method, path, mergedHeaders, params);

            const res = await handleResponse(
                method,
                path,
                response
            );

            return res
        } catch (error) {
            if (config.logger.enabled) {
                logger.error(error, method, path)
            }

            throw error
        }
    }


    return request;
}
