import {SUPPLIER_ACTION_TYPE} from './../actions/constants'

const initialState = {
    suppliers: [], //TODO: Break out to common?
    contactPeople: [], //TODO: Break out to common?
    companies: null, //TODO: Break out to common?
    selectedCompany: null, //TODO: Break out to common?
    identificationRequirementLevels: [], //TODO: Break out to common?
    supplierPage: {
        selectedSupplier: null,
        newSupplier: null,
        detailsVisible: false,
    },
};

const supplierReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUPPLIER_ACTION_TYPE.SUPPLIER_SELECTED: {
            const {supplier} = action.payload;
            return {
                ...state, supplierPage: {
                    ...state.supplierPage, selectedSupplier: supplier
                }
            }
        }
        case SUPPLIER_ACTION_TYPE.CLEAR_SUPPLIER: {
            return {
                ...state, supplierPage: {
                    ...state.supplierPage, selectedSupplier: null
                }
            }
        }
        case SUPPLIER_ACTION_TYPE.SUPPLIERS_RETRIEVED: {
            const {suppliers} = action.payload;
            return {...state, suppliers};
        }
        case SUPPLIER_ACTION_TYPE.COMPANIES_RETRIEVED: {
            const {companies} = action.payload;
            return {...state, companies}
        }
        case SUPPLIER_ACTION_TYPE.COMPANY_SELECTED: {
            const {company} = action.payload;
            return {...state, selectedCompany : company}
        }
        case SUPPLIER_ACTION_TYPE.CONTACT_PEOPLE_RETRIEVED: {
            const {contactPeople} = action.payload;
            return {...state, contactPeople}
        }
        case SUPPLIER_ACTION_TYPE.IDENTIFICATION_REQUIREMENT_LEVEL_RETRIEVED: {
            const {identificationRequirementLevels} = action.payload;
            return {...state, identificationRequirementLevels}
        }
    }
    return state;
};

export default supplierReducer;