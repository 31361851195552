export const SUPPLIER_ACTION_TYPE = {
  SUPPLIER_SELECTED: "SUPPLIER_SELECTED",
  SUPPLIERS_RETRIEVED: "SUPPLIERS_RETRIEVED",
  COMPANIES_RETRIEVED: "COMPANIES_RETRIEVED",
  COMPANY_SELECTED: "COMPANY_SELECTED",
  CLEAR_SUPPLIER: "CLEAR_SUPPLIER",
  CONTACT_PEOPLE_RETRIEVED: "CONTACT_PEOPLE_RETRIEVED",
  IDENTIFICATION_REQUIREMENT_LEVEL_RETRIEVED:
    "IDENTIFICATION_REQUIREMENT_LEVEL_RETRIEVED",
};

export const USER_FEEDBACK_ACTION_TYPE = {
  API_CALL_STARTED: "API_CALL_STARTED",
  API_CALL_FINISHED: "API_CALL_FINISHED",
  ERROR_RECEIVED: "ERROR_RECEIVED",
  ERROR_CLEARED: "ERROR_CLEARED",
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
};

export const COWORKER_ACTION_TYPE = {
  COWORKERS_RETRIEVED: "COWORKERS_RETRIEVED",
  COWORKER_SELECTED: "COWORKER_SELECTED",
  CLEAR_COWORKER: "CLEAR_COWORKER",
};

export const LOGIN_ACTION_TYPE = {
  LOGIN_INITIATED: "LOGIN_INITIATED",
  LOGIN_SUCCESSFUL: "LOGIN_SUCCESSFUL",
  LOGOUT_SUCCESSFUL: "LOGOUT_SUCCESSFUL",
  FINALIZE_ACCOUNT_INFO_RETRIEVED: "FINALIZE_ACCOUNT_INFO_RETRIEVED",
};

export const SHOP_ACTION_TYPE = {
  SHOPS_RETRIEVED: "SHOPS_RETRIEVED",
  SHOPS_FOR_COMPANY_RETRIEVED: "SHOPS_FOR_COMPANY_RETRIEVED",
};

export const ASSIGNMENT_DESCRIPTION_ACTION_TYPE = {
  ASSIGNMENT_DESCRIPTION: "ASSIGNMENT_DESCRIPTION",
};

export const ASSIGNMENT_ACTION_TYPE = {
  ASSIGNMENT_RETRIEVED: "ASSIGNMENT_RETRIEVED",
  ASSIGNMENT_SELECTED: "ASSIGNMENT_SELECTED",
  CLEAR_ASSIGNMENT: "CLEAR_ASSIGNMENT",
};
