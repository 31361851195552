import React, {Component} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import ReactSVG from 'react-svg';
import styles from './NavMenu.module.scss'
import {logOutSuccessful} from "../../actions";
import {logOut} from "../../Api";

class NavMenu extends Component {
    displayName = NavMenu.name;
    
    state = {
        redirect: false
    };
    
    renderSuppliersIfAllowed = () => {
        if (this.props.accessSuppliers !== true)
            return;

        return (<LinkContainer to='/suppliers'>
            <Nav.Link className={styles.link}>
                <FormattedMessage id="suppliers"/>
            </Nav.Link>
        </LinkContainer>);
    };

    renderCoworkersIfAllowed = () => {
        if (this.props.accessCoWorkers !== true)
            return;

        return (<LinkContainer to='/coworkers' exact>
            <Nav.Link className={styles.link}>
                <FormattedMessage id="coWorkers"/>
            </Nav.Link>
        </LinkContainer>);
    };

    renderCompaniesIfAllowed = () => {
        if (this.props.accessSuppliers !== true)
            return;

        return (<LinkContainer to='/companies' exact>
            <Nav.Link className={styles.link}>
                <FormattedMessage id="company"/>
            </Nav.Link>
        </LinkContainer>);
    };
    
    

    logOut = async (e) => {
        let result = await logOut();

        try {
            this.props.onLogOutSuccessful();
        }catch (e) {
        }
    };

    render() {
        return (
            <Navbar className="col-12" expand="lg">
                <Navbar.Brand className="col-4" href="/">
                    <ReactSVG src="/img/logo.svg" className="m-auto"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse className="main-menu col-4">
                    <Nav className={styles.nav}>
                        <LinkContainer to='/assignments' exact>
                            <Nav.Link className={styles.link}>
                                <FormattedMessage id="assignments"/>
                            </Nav.Link>
                        </LinkContainer>
                        {this.renderCoworkersIfAllowed()}
                        {this.renderSuppliersIfAllowed()}
                        {this.renderCompaniesIfAllowed()}
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="secondary-menu col-4">
                    <Nav className={styles.nav}>
                        <Nav.Link className={styles.link}>
                            {this.props.user.fullName}
                        </Nav.Link>
                    </Nav>
                    <Nav className={styles.nav}>
                        <Nav.Link className={styles.link}>
                            <span onClick={event => this.logOut(event)}>
                                Logga ut
                            </span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}


const mapStateToProps = ({auth}) => ({
    isLoggedIn: auth.isLoggedIn,
    accessSuppliers: auth.accessSuppliers,
    accessCoWorkers: auth.accessCoWorkers,
    user: auth.user
});

const mapDispatchToActions = {
    onLogOutSuccessful: logOutSuccessful
};

export default connect(mapStateToProps, mapDispatchToActions)(NavMenu);