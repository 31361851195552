import fetch from './../lib/fetch-client'

const request = fetch();

export function getSuppliers() {
    return request({
        method: "GET",
        path: "supplier",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        let {suppliers} = body;
        return suppliers;
    });
}

export function getCompanies() {
    return request({
        method: "GET",
        path: "company",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function createCompany(data) {
    const {companyName, identificationRequirementLevel, stores} = data;
    let storeGuids = stores.map(store => store.value);

    return request({
        method: "post",
        path: "company/create",
        params: {
            companyName,
            identificationRequirementLevel,
            stores: storeGuids,
        }
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function updateCompany(data) {
    const {companyId, companyName, identificationRequirementLevel, stores} = data;

    let storeGuids = stores.map(store => store.value);
    return request({
        method: "PATCH",
        path: "company/Update",
        params: {
            companyId,
            companyName,
            identificationRequirementLevel,
            stores: storeGuids,
        }
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function deleteCompany(companyId) {
    return request({
        method: "DELETE",
        path: "company/Delete?id="+companyId,
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}


export function getContactPeople() {
    return request({
        method: "GET",
        path: "contactpeople",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function getIdentificationLevelRequirements() {
    return request({
        method: "GET",
        path: "identificationRequirementLevel",
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        return body;
    });
}

export function createSuppliers(
    {
        firstName, lastName, email, confirmEmail,
        company, identificationRequirementLevel, contactPerson
    }) {
    {
        return request({
            method: "POST",
            path: "supplier/Create",
            params: {
                firstName,
                lastName,
                email,
                confirmEmail,
                companyId: company,
                identificationRequirementLevel,
                contactPersonId: contactPerson
            }
        }).then(function ({status, headers, body}) {
            if (status !== 200) {
                throw new Error(`Invalid status code. Status: ${status}`);
            }

            let {suppliers} = body;
            return suppliers;
        });
    }
}

export function updateSuppliers(
    {
        key, firstName, lastName, cellphoneNumber, email, confirmEmail,
        company, identificationRequirementLevel, contactPerson
    }) {
    return request({
        method: "PATCH",
        path: "supplier/Update",
        params: {
            key,
            firstName,
            lastName,
            cellphoneNumber,
            email,
            confirmEmail,
            companyId: company,
            identificationRequirementLevel,
            contactPersonId: contactPerson
        },
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        let {suppliers} = body;
        return suppliers;
    });
}

export function deleteSuppliers(supplierId) {
    return request({
        method: "DELETE",
        path: "supplier/Delete?id=" + supplierId,
    }).then(function ({status, headers, body}) {
        if (status !== 200) {
            throw new Error(`Invalid status code. Status: ${status}`);
        }

        let {suppliers} = body;
        return suppliers;
    });
}