import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedHTMLMessage} from 'react-intl';
import {Form} from 'antd';
import {Container, Row, Col} from 'react-bootstrap';
import ReactSVG from 'react-svg';
import {login} from './../../Api/account';
import LoginForm from "../login/login-form";
import styles from './LoginPage.module.scss';
import './../../actions/'
import {apiCallFinished, apiCallStarted, loginInitiated, loginSuccessful} from "../../actions";
import {Redirect} from 'react-router-dom';
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";

const WrappedLoginForm = Form.create({name: 'login'})(LoginForm);

class LoginPage extends Component {
    state = {
        redirect: false
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        let {userId, password, remember} = this.form.getFieldsValue();
        this.props.onLoginInitiated({userId, password, remember});
        
        try {
            this.props.onApiCallStarted();
            let loginResponse = await login({userId, password, rememberMe: remember});
            this.props.onApiCallFinished();

            this.props.onLoginSuccessful(loginResponse);
            this.setState({
                redirect: true
            });
        } catch (e) {
            this.props.onErrorReceived(e)
        }
    }

    setFormRef = (form) => {
        this.form = form;
    };

    renderRedirect = () => {
        if (this.props.auth.isLoggedIn) {
            return <Redirect to='/assignments'/>
        }
    };

    render() {
        const {isLoggingIn} = this.props;
        const {credentials} = this.props.auth;
        return (
            <Container>
                <UserFeedback>
                    <Row>
                        <Col xs={8} className="mx-auto">
                            <Row className={styles.head}>
                                <ReactSVG src="/img/logotype.svg" className="m-auto"/>
                            </Row>

                            <Row className={styles.form}>
                                <Col xs={9} className="mx-auto">
                                    {this.renderRedirect()}
                                    <h1 className={styles.heading}>
                                        <FormattedHTMLMessage id="logIn"/>
                                    </h1>
                                    {isLoggingIn &&
                                    <p>Logging in</p>
                                    }
                                    <WrappedLoginForm ref={this.setFormRef} onSubmit={this.handleSubmit}
                                        credentials={credentials} />
                                    <i id="consentInformation"><FormattedHTMLMessage id="consentInformation" /></i>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </UserFeedback>
            </Container>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToActions = {
    onLoginInitiated: loginInitiated,
    onLoginSuccessful: loginSuccessful,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(LoginPage);