import React, {Component} from 'react';
import {
    Form, Button, Col, Modal,
} from 'antd';
import {connect} from "react-redux";
import LabeledInput from './../../components/labeled-input'
import {apiCallFinished, apiCallStarted, clearCoworkers, coworkersRetrieved} from "../../actions";
import {createCoworker, updateCoworker} from "../../Api/coworker";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";
import {AvatarUpload} from "../../components/avatar-upload";
import {Row} from "antd/lib/grid";
import {deleteCoworker} from "../../Api";

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class CoworkerForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            password: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.form.validateFields();
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let userData = null;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                userData = values;
            } else {
            }
        });

        let {isEditMode = false} = this.props;
        if (userData != null) {
            try {
                let coworkerResult = null;

                this.props.onApiCallStarted();

                if (isEditMode) {
                    let {key} = this.props.selectedCoworker;
                    coworkerResult = await updateCoworker({...userData, key});
                } else {
                    coworkerResult = await createCoworker(userData);
                }

                this.props.onApiCallFinished();

                this.props.onCoworkersRetrieved(coworkerResult);

            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    buttonText = () => {
        const {isEditMode = false} = this.props;
        const {isFinalizeAccount = false} = this.props;

        if (isEditMode) {
            return (<FormattedMessage id="save"/>)
        } else if (isFinalizeAccount) {
            return (<FormattedMessage id="update"/>)
        }

        return (<FormattedMessage id="add"/>)
    };

    renderSubmitButton = () => {
        const {isEditMode = false} = this.props;

        const {selectedCoworker, onApiCallStarted, onApiCallFinished, closeModal, onCoworkersRetrieved, onClearCoworkers} = this.props;
        let messageId = isEditMode ? "save" : "add";
        return (<Form.Item>
            <Row>
                <Col span={6}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        {this.buttonText()}
                    </Button>
                </Col>
                {isEditMode &&
                <Col span={6} offset={12}>
                    <Button type="danger" className="login-form-button" onClick={(e) => {
                        Modal.confirm({
                            title: 'Ta bort medarbetare',
                            content: 'Är du säker på att du vill ta bort den här medarbetaren',
                            okText: 'Ja',
                            okType: 'danger',
                            cancelText: 'Nej',
                            onOk() {
                                onApiCallStarted();
                                closeModal();
                                let {key} = selectedCoworker;
                                deleteCoworker(key).then(function (body) {
                                    onApiCallFinished();
                                    onCoworkersRetrieved(body);
                                    onClearCoworkers();
                                    closeModal();
                                });
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>
                        <FormattedHTMLMessage id="remove"/>
                    </Button>
                </Col>
                }
            </Row>
        </Form.Item>)
    };

    renderComponent() {
        const {isEditMode = false} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {isFinalizeAccount = false} = this.props;

        let selectedUser;
        if (isFinalizeAccount) {
            selectedUser = this.props.finalizeAccountInfo ? this.props.finalizeAccountInfo
                : {
                    firstName: "",
                    lastName: "",
                    email: "",
                };
        } else {
            selectedUser = this.props.selectedCoworker ? this.props.selectedCoworker
                : {
                    firstName: "",
                    lastName: "",
                    email: "",
                };
        }

        const {requiresPhoneNumber = true} = selectedUser;

        if (isEditMode && (!selectedUser || selectedUser.firstName == "")) {
            return (<div/>)
        }

        const formComponent = (<Form onSubmit={(e) => {
            e.preventDefault();
            this.props.onFormPosted(e, this.props.form, isEditMode);
        }}>
            <Form.Item>
                {getFieldDecorator('firstName', {
                    rules: [{required: true, message: 'Ange förnamn'}],
                    initialValue: selectedUser.firstName

                })(
                    <LabeledInput labelKey={'firstName'} type="text"/>
                )}
            </Form.Item>
            {isFinalizeAccount &&
            <Form.Item>
                {getFieldDecorator('lastName', {
                    rules: [{required: true, message: <FormattedMessage id="provideLastName"/>}],
                    initialValue: selectedUser.lastName
                })(
                    <LabeledInput labelKey={'lastName'} type="text"/>
                )}
            </Form.Item>
            }
            <Form.Item>
                {getFieldDecorator('email', {
                    rules: [{required: true, message: <FormattedMessage id="provideEmail"/>}],
                    initialValue: selectedUser.email
                })(
                    <LabeledInput labelKey={'email'} type="text"/>
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('confirmEmail', {
                    rules: [{required: true, message: <FormattedMessage id="confirmEmail"/>}],
                    initialValue: null
                })(
                    <LabeledInput labelKey={'confirmEmail'} type="text"/>
                )}
            </Form.Item>
            {isFinalizeAccount && <div>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{required: true, message: <FormattedMessage id="providePassword"/>}],
                    })(
                        <LabeledInput labelKey={'providePassword'} type="password"/>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('confirmPassword', {
                        rules: [{required: true, message: <FormattedMessage id="confirmPassword"/>}],
                    })(
                        <LabeledInput labelKey={'confirmPassword'} type="password"/>
                    )}
                </Form.Item>
            </div>}
            {requiresPhoneNumber &&
            <div>
                <Form.Item>
                    {getFieldDecorator('cellphoneNumber', {
                        rules: [{required: true, message: <FormattedMessage id="provideMobilePhoneNumber"/>}],
                        initialValue: selectedUser.mobilePhoneNumber
                    })(
                        <LabeledInput labelKey={'mobilePhoneNumber'} type="text"/>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('confirmCellphoneNumber', {
                        rules: [{required: true, message: <FormattedMessage id="confirmMobilePhoneNumber"/>}],
                        initialValue: null
                    })(
                        <LabeledInput labelKey={'confirmMobilePhoneNumber'} type="text"/>
                    )}
                </Form.Item>
            </div>
            }
            {this.props.finalizeAccountToken && selectedUser.requiresAvatar &&
            <Form.Item>
                <AvatarUpload name={"image"} finalizeAccountToken={this.props.finalizeAccountToken}/>
            </Form.Item>
            }
            <Form.Item>
                {this.renderSubmitButton()}
            </Form.Item>
        </Form>);

        return formComponent;
    }

    render() {
        return (
            <UserFeedback>
                {this.renderComponent()}
            </UserFeedback>);
    }
}

const mapStateToProps = ({coworker, auth}) => ({
    selectedCoworker: coworker.selectedCoworker,
    finalizeAccountInfo: auth.finalizeAccountInfo
});

const mapDispatchToActions = {
    onClearCoworkers: clearCoworkers,
    onCoworkersRetrieved: coworkersRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(CoworkerForm);