import {SHOP_ACTION_TYPE} from './../actions/constants'

const initialState = {
    shops: [], //TODO: Break out to common?
    shopsForCompany: []
};

const shopReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOP_ACTION_TYPE.SHOPS_RETRIEVED: {
            const {shops} = action.payload;
            return {shops}
        }
        case SHOP_ACTION_TYPE.SHOPS_FOR_COMPANY_RETRIEVED: {
            const {shops} = action.payload;
            return {...state, shopsForCompany: shops}
        }
    }
    return state;
};

export default shopReducer;