import React, {Component} from 'react';
import LabeledInput from './../../components/labeled-input'
import './index.css';

import {
    Form, Icon, Input, Button, Checkbox,
} from 'antd';


function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default class ResetAccountForm extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        console.log("form.props: ", this.props.form);
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });


        this.props.onSubmit(e);
    };

    handleChange = (event) => {
        //this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {userId = "", password = "", rememberMe = ""} = this.props.credentials;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item>
                    {getFieldDecorator('userId', {
                        rules: [
                            {required: true, message: 'Vänligen ange din email'}],
                        initialValue: userId
                    })(
                        <LabeledInput labelKey={'email'}
                                      prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                      handleChange={this.handleChange}/>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Bekräfta
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}