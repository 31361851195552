import fetch from './../lib/fetch-client'

const request = fetch();

export function getAssignment() {
    return request({
        method: "GET",
        path: "assignment",
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function testAssignment() {
    return request({
        method: "GET",
        path: "assignment/test",
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function createAssignment(data) {
    let {coWorker, startDate, endDate, stores, workDescription} = data;

    let storeGuids = stores.map(store => store.value);

    startDate = startDate.utc().format('YYYY-MM-DD');
    endDate = endDate.utc().format('YYYY-MM-DD');
    return request({
        method: "POST",
        path: "assignment/Create",
        params: {
            coworkers: coWorker,
            stores: storeGuids,
            workDescription,
            startDate,
            endDate

        }
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function updateAssignment(data) {
    let {assignmentId, coWorker, startDate, endDate, stores, workDescription} = data;

    let storeGuids = stores.map(store => store.value);
    
    startDate = startDate.utc().format('YYYY-MM-DD');
    endDate = endDate.utc().format('YYYY-MM-DD');
    
    return request({
        method: "PATCH",
        path: "assignment/Update",
        params: {
            assignmentId,
            coworkers: coWorker,
            stores: storeGuids,
            workDescription,
            startDate,
            endDate
        }
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function deleteAssignment(id) {
    return request({
        method: "DELETE",
        path: "assignment/Delete?id=" + id,
    }).then(function ({status, headers, body}) {
        return body;
    });
}
