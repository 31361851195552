import {ASSIGNMENT_DESCRIPTION_ACTION_TYPE} from './../actions/constants'

const initialState = {
    assignmentDescriptions: [], //TODO: Break out to common?
};

const assignmentDescriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGNMENT_DESCRIPTION_ACTION_TYPE.ASSIGNMENT_DESCRIPTION: {
            const {assignmentDescriptions} = action.payload;
            return {assignmentDescriptions}
        }
    }
    return state;
};

export default assignmentDescriptionReducer;