import React, {Component} from 'react';
import {Form, Button, Col, Modal,} from 'antd';
import './index.css';
import LabeledInput from './../../components/labeled-input'
import Select from "../../components/select";
import Autocomplete from "../../components/autocomplete-input";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {createSuppliers, getSuppliers, updateSuppliers} from '../../Api/supplier';
import {apiCallFinished, apiCallStarted, supplierSelected, suppliersRetrieved} from "../../actions";
import {Row} from "antd/lib/grid";
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";
import {deleteAssignment, deleteSuppliers} from "../../Api";

class SupplierForm extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        // To disabled submit button at the beginning.
        this.props.form.validateFields();
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let supplierData = null;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                supplierData = values;
            }
        });

        let {isEditMode = false} = this.props;
        if (supplierData != null) {
            try {
                this.props.onApiCallStarted();

                let supplierResult = null;

                if (isEditMode) {
                    //TODO: Use ID from form?
                    let {key} = this.props.selectedSupplier;

                    supplierResult = await updateSuppliers({...supplierData, key});
                } else {
                    supplierResult = await createSuppliers(supplierData);
                }

                this.props.onApiCallFinished();
                this.props.onSuppliersRetrieved(supplierResult);
                await this.props.closeModal();

            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    buttonText = () => {
        let {isEditMode = false} = this.props;

        let messageId = isEditMode ? "save" : "add";
        return (<FormattedHTMLMessage id={messageId}/>);
    };

    renderSubmitButton = () => {
        const {isEditMode = false} = this.props;

        const {selectedSupplier, onApiCallStarted, onApiCallFinished, closeModal, onSuppliersRetrieved, onSupplierSelected} = this.props;
        let messageId = isEditMode ? "save" : "add";
        return (<Form.Item>
            <Row>
                <Col span={6}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button">
                        {this.buttonText()}
                    </Button>
                </Col>
                {isEditMode &&
                <Col span={6} offset={12}>
                    <Button type="danger" className="login-form-button" onClick={(e) => {
                        Modal.confirm({
                            title: 'Ta bort leverantör',
                            content: 'Är du säker på att du vill ta bort den här leverantören',
                            okText: 'Ja',
                            okType: 'danger',
                            cancelText: 'Nej',
                            onOk() {
                                onApiCallStarted();
                                closeModal();
                                let {key} = selectedSupplier;

                                deleteSuppliers(key).then(function (body) {
                                    onApiCallFinished();
                                    onSuppliersRetrieved(body);
                                    onSupplierSelected(null);
                                    closeModal();
                                });
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>
                        <FormattedHTMLMessage id="remove"/>
                    </Button>
                </Col>
                }
            </Row>
        </Form.Item>)
    };

    render() {
        const {getFieldDecorator} = this.props.form;

        const selectedSupplier = this.props.selectedSupplier ? this.props.selectedSupplier
            : {
                firstName: "",
                lastName: "",
                email: "",
                cellphoneNumber: "",
                identificationRequirementLevel: "",
                company: "",
                contactPerson: ""
            };
        return (
            <UserFeedback>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item>
                        {getFieldDecorator('firstName', {
                            rules: [{required: true, message: <FormattedMessage id="provideFirstName"/>}],
                            initialValue: selectedSupplier.firstName
                        })(
                            <LabeledInput labelKey={'firstName'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('lastName', {
                            rules: [{required: true, message: <FormattedMessage id="provideLastName"/>}],
                            initialValue: selectedSupplier.lastName
                        })(
                            <LabeledInput labelKey={'lastName'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{required: true, message: <FormattedMessage id="provideEmail"/>}],
                            initialValue: selectedSupplier.email

                        })(
                            <LabeledInput labelKey={'email'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('confirmEmail', {
                            rules: [{required: true, message: <FormattedMessage id="confirmEmail"/>}],
                            initialValue: selectedSupplier.email
                        })(
                            <LabeledInput labelKey={'confirmEmail'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Row span={24}>
                            <Col span={18}>
                                {getFieldDecorator('company', {
                                    rules: [{required: true, message: <FormattedMessage id="selectOrSupplyCompany"/>}],
                                    initialValue: selectedSupplier.companyId
                                })(
                                    <Autocomplete labelKey={'company'} dataSource={this.props.companies}
                                                  placeholderLabelKey="selectOrSupplyCompany"/>
                                )}
                            </Col>
                            <Col span={6}>
                                <Button type="primary" onClick={this.props.showCompanyModal}>
                                    <FormattedMessage id="newCompany"/>
                                </Button>
                            </Col>
                        </Row>

                    </Form.Item>
                    < Form.Item>
                        {getFieldDecorator('contactPerson',
                            {
                                rules: [{required: true, message: <FormattedMessage id="selectContactPerson"/>}],
                                initialValue:
                                selectedSupplier.contactPersonId
                            }
                        )
                        (
                            <Select labelKey={'contactPersonAtReitan'} selectOptions={this.props.contactPeople}
                                    placeholderLabelKey="selectContactPerson"/>
                        )
                        }
                    </Form.Item>
                    <Form.Item>
                        {this.renderSubmitButton()}
                    </Form.Item>
                </Form>
            </UserFeedback>
        );
    }
}

const mapStateToProps = ({supplier}) => ({
    selectedSupplier: supplier.supplierPage.selectedSupplier,
    companies: supplier.companies,
    contactPeople: supplier.contactPeople,
    identificationRequirementLevels: supplier.identificationRequirementLevels,
});

const mapDispatchToActions = {
    onSupplierSelected:supplierSelected,
    onSuppliersRetrieved: suppliersRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(SupplierForm);