import React, {Component} from 'react';
import {Form, Button, TreeSelect, Col, Modal,} from 'antd';
import './index.css';
import LabeledInput from './../../components/labeled-input'
import Select from "../../components/select";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import { getCompanies} from '../../Api/supplier';
import {
    apiCallFinished,
    apiCallStarted,
    companiesRetrieved,
    shopsForCompanyRetrieved,
    shopsRetrieved,
    suppliersRetrieved
} from "../../actions";
import {createCompany, deleteCompany, updateCompany} from "../../Api";
import moment from "../assignments/assignment-form";
import {errorReceived} from "../../actions/user-feedback";
import UserFeedback from "../../components/user-feedback";
import {Row} from "antd/lib/grid";

class CompanyForm extends Component {

    state = {
        value: "",
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.props.form.validateFields();

        let companies = await getCompanies();
        this.props.onCompaniesRetrieved(companies);
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        let companyData = null;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                companyData = values;
            }
        });


        if (companyData != null) {
            let {isEditMode = false} = this.props;
            try {
                let companyResult;

                this.props.onApiCallStarted();

                if (isEditMode) {
                    let {value: companyId} = this.props.selectedCompany;
                    companyResult = await updateCompany({companyId, ...companyData});
                } else {
                    companyResult = await createCompany(companyData);
                }

                this.props.onApiCallFinished();

                this.props.onCompaniesRetrieved(companyResult);
                await this.props.closeModal();


            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    buttonText = () => {
        let {isEditMode = false} = this.props;
        if (isEditMode) {
            return "Spara";
        } else {
            return "Lägg till";
        }
    };

    renderSubmitButton = () => {
        const {isEditMode = false} = this.props;

        
        const {selectedCompany, onCompaniesRetrieved, onApiCallStarted, onApiCallFinished, closeModal} = this.props;
        let messageId = isEditMode ? "save" : "add";
        return (<Form.Item>
            <Row>
                <Col span={6}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        {this.buttonText()}
                    </Button>
                </Col>
                {isEditMode &&
                <Col span={6} offset={12}>
                    <Button type="danger" className="login-form-button" onClick={(e) => {
                        Modal.confirm({
                            title: 'Ta bort företag',
                            content: 'Är du säker på att du vill ta bort det här företaget?',
                            okText: 'Ja',
                            okType: 'danger',
                            cancelText: 'Nej',
                            onOk() {
                                onApiCallStarted();
                                closeModal();
                                let {value: companyId} = selectedCompany;

                                deleteCompany(companyId).then(function (body) {
                                    onApiCallFinished();
                                    onCompaniesRetrieved(body);
                                    closeModal();
                                });
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>
                        <FormattedHTMLMessage id="remove"/>
                    </Button>
                </Col>
                }
            </Row>
        </Form.Item>)
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        const {selectedCompany} = this.props;

        const companyName = selectedCompany ? selectedCompany.text : "";
        const identificationLevel = selectedCompany ? selectedCompany.identificationLevel : "";


        const selectedShops = this.props.selectedCompany ? this.props.selectedCompany.storeList.map(shop => ({
            label: shop.label,
            value: shop.value
        })) : [];

        return (
            <UserFeedback>

                <Form onSubmit={this.handleSubmit}>
                    <Form.Item>
                        {getFieldDecorator('companyName', {
                            rules: [{required: true, message: <FormattedMessage id="provideCompanyName"/>}],
                            initialValue: companyName
                        })(
                            <LabeledInput labelKey={'companyName'} type="text"/>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('identificationRequirementLevel', {
                            rules: [{required: true, message: <FormattedMessage id="selectIdentificationLevel"/>}],
                            initialValue: identificationLevel
                        })(
                            <Select labelKey={'identificationLevel'}
                                    selectOptions={this.props.identificationRequirementLevels}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <label>
                            <FormattedHTMLMessage id="shops"/>
                        </label>
                        {getFieldDecorator('stores', {
                            rules: [{required: true, message: 'Ange en eller fler butiker'}],
                            initialValue: selectedShops
                        })(
                            <TreeSelect
                                showSearch
                                style={{width: 300}}
                                treeNodeFilterProp="title"
                                labelInValue={true}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                placeholder="Please select"
                                allowClear
                                treeDefaultExpandAll
                                multiple
                                ref={(input) => { this.shopComponent = input; }}
                                onChange={(value, label, extra) =>{this.shopComponent.focus()}}
                                treeData={this.props.shops}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {this.renderSubmitButton()}
                    </Form.Item>
                </Form>
            </UserFeedback>
        );
    }
}

const mapStateToProps = ({supplier, shop}) => ({
    selectedSupplier: supplier.supplierPage.selectedSupplier,
    companies: supplier.companies,
    selectedCompany: supplier.selectedCompany,
    contactPeople: supplier.contactPeople,
    identificationRequirementLevels: supplier.identificationRequirementLevels,
    shops: shop.shops,
});

const mapDispatchToActions = {
    onSuppliersRetrieved: suppliersRetrieved,
    onShopsRetrieved: shopsRetrieved,
    onCompaniesRetrieved: companiesRetrieved,
    onShopsForCompanyRetrieved: shopsForCompanyRetrieved,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(CompanyForm);