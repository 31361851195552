import React from 'react';
import {connect} from 'react-redux';
import {
    Table, Input, Button, Icon, Row, Col, Modal, Form
} from 'antd';
import {FormattedMessage} from "react-intl";
import Highlighter from 'react-highlight-words';
import CompanyForm from "./company-form";
import ReactSVG from 'react-svg';
import {companySelected} from "../../actions";

const WrappedSupplierForm = Form.create({name: 'companyForm'})(CompanyForm);

class SupplierTable extends React.Component {
    state = {
        searchText: '',
        selectedElement: {},
        currentIndex: 0
    };

    async componentDidMount() {
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    closeModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys, selectedKeys, confirm, clearFilters,
                         }) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder="Sök"
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Sök
                </Button>
                <Button
                    onClick={() => this.handleReset(clearFilters)}
                    size="small"
                    style={{width: 90}}
                >
                    Nollställ
                </Button>
            </div>
        ),
        filterIcon: filtered => <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: (text) => (
            <Highlighter
                highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({searchText: selectedKeys[0]});
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.setState({searchText: ''});
    };

    itemSelected = (event, record, rowIndex) => {
        this.setState({selectedElement: record, currentIndex: rowIndex}, () => {
            if (this.form !== undefined) {
                this.form.resetFields();
            }
        });

        this.sate = {...this.sate, currentIndex: rowIndex};
        this.props.onCompanySelected(record);
    };

    closeSelected = () => {
        this.props.onCompanySelected(null);
    };

    isEditMode = () => {
        return true;
    };

    modalTitle = () => {
        if (this.isEditMode()) {
            return <FormattedMessage id="editCompany"/>
        } else {
            return <FormattedMessage id="newCompany"/>
        }
    };

    setFormRef = (form) => {
        this.form = form;
    };

    setTableRef = (table) => {
        this.table = table;
    };

    renderTable = () => {
        if (!this.props.companies) {
            return (<div/>);
        }

        const columns = [{
            title: <FormattedMessage id="name"/>,
            dataIndex: 'text',
            key: 'companyName',
            width: '95%',
            ...this.getColumnSearchProps('text'),
            sorter: (a, b) => {
                return ('' + a.text).localeCompare(b.text)
            },
            sortDirections: ['descend', 'ascend'],
        }, {
            title: 'Redigera',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (<Icon type="edit" theme="outlined" onClick={(e) => {
                e.preventDefault();
                this.showModal()
            }}/>)
        }];

        const {selectedSupplier, onFormPosted, ...remainingProps} = this.props;
        return (<div>
            <Modal
                footer={null}
                title={this.modalTitle()}
                visible={this.state.visible}
                onCancel={this.handleCancel}
            >
                <WrappedSupplierForm
                    isEditMode
                    ref={this.setFormRef}
                    supplier={selectedSupplier}
                    onFormPosted={onFormPosted}
                    closeModal={this.closeModal}
                    closeCompanyModal={this.props.closeCompanyModal} showCompanyModal={this.props.showCompanyModal}
                />
            </Modal>
            <Table
                columns={columns}
                dataSource={this.props.companies}
                {...remainingProps}
                ref={this.setTableRef}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            this.itemSelected(event, record, rowIndex);
                        }, // click row
                        onDoubleClick: (event) => {
                        }, // double click row
                        onContextMenu: (event) => {
                        }, // right button click row
                        onMouseEnter: (event) => {
                        }, // mouse enter row
                        onMouseLeave: (event) => {
                        }, // mouse leave row
                    };
                }}/>
        </div>);
    };

    render() {
        return (this.renderTable());
    }
}

const mapStateToProps = ({supplier}) => ({
    selectedSupplier: supplier.supplierPage.selectedSupplier,
    companies: supplier.companies,
    selectedCompany: supplier.selectedCompany
});

const mapDispatchToActions = {
    onCompanySelected: companySelected
};

export default connect(mapStateToProps, mapDispatchToActions)(SupplierTable);