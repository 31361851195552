import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Modal} from 'antd';
import {FormattedMessage} from "react-intl";
import CoworkerForm from './coworker-form';
import CoworkerTable from "./coworker-table";
import {apiCallFinished, apiCallStarted, clearCoworkers, coworkersRetrieved} from "../../actions";
import {Layout} from '../../components/Layout';
import PageHead from '../../components/PageHead';
import { createCoworker, getCoworker, updateCoworker, getCoworkerTable} from './../../Api/coworker';
import {Container, Row, Col} from 'react-bootstrap';
import {errorReceived} from "../../actions/user-feedback";

const WrappedCoworkerForm = Form.create({name: 'coworkerForm'})(CoworkerForm);

class CoworkersPage extends Component {
    state = {visible: false};

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let coworkers = await getCoworker();
        let coworkersLabelled = labelEmailConfirmedCoworkers(coworkers);
        this.props.onCoworkersRetrieved(coworkersLabelled);
    }

    showModal = () => {
        this.props.onModalShowed();
        this.setState({
            visible: true,
        });
    };

    closeModal = () => {
        this.setState({
            visible: false,
        });
    };

    handleOk = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleFormSubmit = async (e, form, isEditMode, closeModal) => {
        e.preventDefault();

        let userData = null;
        form.validateFields((err, values) => {
            if (!err) {
                userData = values;
            } else {
            }
        });

        if (userData != null) {
            let coworkerResult = null;

            try {
                this.props.onApiCallStarted();

                if (isEditMode) {
                    let {key} = this.props.selectedCoworker;
                    coworkerResult = await updateCoworker({...userData, key});
                } else {
                    coworkerResult = await createCoworker(userData);
                }

                this.props.onApiCallFinished();

                this.props.onCoworkersRetrieved(coworkerResult);
                this.closeModal();

                form.resetFields();

                try {
                    //Close any other modals we might want to when this call finishes
                    closeModal();
                } catch (e) {
                }

            } catch (e) {
                this.props.onErrorReceived(e)
            }
        }
    };

    render() {
        const {isSupplier} = this.props;
        return (
            <Layout>
                <Container>
                    <PageHead
                        actionId="newCoworker"
                        headingId="coWorkers"
                        onActionClick={this.showModal}
                        showAddButton={isSupplier}
                    />

                    <CoworkerTable onSubmit={this.handleFormSubmit} onFormPosted={this.handleFormSubmit}/>

                    <Modal
                        footer={null}
                        title={<FormattedMessage id="newCoworker"/>}
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <WrappedCoworkerForm onFormPosted={this.handleFormSubmit}/>
                    </Modal>
                </Container>
            </Layout>
        );
    }
}

const mapStateToProps = ({coworker, auth}) => ({
    coworker,
    isSupplier: !auth.accessSuppliers && auth.accessCoWorkers,
    selectedCoworker: coworker.selectedCoworker,
});

const mapDispatchToActions = {
    onCoworkersRetrieved: coworkersRetrieved,
    onModalShowed: clearCoworkers,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

const labelEmailConfirmedCoworkers = (coworkers) => {

    coworkers.forEach((coworker) => {
        if (coworker.emailConfirmed) {
            coworker.emailConfirmed = "Ja";
        }
        else { coworker.emailConfirmed = "Nej"; }
    });
    return coworkers;
};

export default connect(mapStateToProps, mapDispatchToActions)(CoworkersPage);
