import sv from 'react-intl/locale-data/sv';
import { addLocaleData } from 'react-intl';

addLocaleData(sv);

/* eslint-disable */
export default {
    sv: {
        ...require('./lang/common.json'),
        ...require('./lang/account.json'),
        ...require('./lang/error-messages'),
    }
};