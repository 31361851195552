import {LOGIN_ACTION_TYPE} from './../actions/constants'

let user = JSON.parse(sessionStorage.getItem('user'));
let jwt = JSON.parse(sessionStorage.getItem('jwt'));

const initialState = {
    user: user,
    jwt: jwt,
    credentials: {
        userId: '',
        password: '',
        rememberMe: false,
    },
    accessSuppliers: getAccessSuppliers(),
    accessCoWorkers: getAccessCoWorkers(),
    finalizeAccountInfo: null,
    isLoggedIn: jwt != null && jwt != "",
    isLoggingIn: false
};

function getAccessSuppliers() {
    if (user == null || user == "")
        return false;

    const {userTypeValue} = user;
    return userTypeValue === 0; //Admin
}

function getAccessCoWorkers() {
    if (user == null || user == "")
        return false;

    const {userTypeValue} = user;
    return userTypeValue <= 1; //Admin or supplier
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_ACTION_TYPE.LOGIN_INITIATED: {
            const {userId, password} = action.payload.loginDetails;
            return {...state, userId, password, isLoggingIn: true};
        }
        case LOGIN_ACTION_TYPE.LOGIN_SUCCESSFUL: {
            const {user, jwt} = action.payload;

            const {userTypeValue} = user;
            const accessSuppliers = userTypeValue === 0; //Admin
            const accessCoWorkers = userTypeValue <= 1; //Admin or supplier

            sessionStorage.setItem('jwt', JSON.stringify(jwt));
            sessionStorage.setItem('user', JSON.stringify(user));

            return {
                ...state,
                password: '',
                user,
                jwt,
                isLoggedIn: true,
                isLoggingIn: false,
                accessSuppliers,
                accessCoWorkers
            };
        }
        case LOGIN_ACTION_TYPE.FINALIZE_ACCOUNT_INFO_RETRIEVED: {
            const {finalizeAccountInfo} = action.payload;

            return {...state, finalizeAccountInfo};
        }
        case LOGIN_ACTION_TYPE.LOGOUT_SUCCESSFUL: {
            sessionStorage.removeItem("jwt");
            sessionStorage.removeItem("user");

            return {
                ...initialState,
                credentials: {
                    userId: '',
                    password: '',
                    rememberMe: false,
                },
                isLoggedIn: false,
                jwt: null,
                user: null
            };
        }
        default:
            break;
    }
    return state;
};

export default authReducer;