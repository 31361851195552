import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedHTMLMessage} from 'react-intl';
import {Form, Modal} from 'antd';
import {Row, Col} from 'react-bootstrap';
import ReactSVG from 'react-svg';

import {forgotPassword} from './../../Api/account';
import ResetAccountForm from "./reset-account-form";
import styles from './ResetAccountPage.module.scss';
import './../../actions/'
import {apiCallFinished, apiCallStarted, errorReceived, loginInitiated, loginSuccessful} from "../../actions";
import UserFeedback from "../../components/user-feedback";

const WrappedResetAccountForm = Form.create({name: 'resetAccount'})(ResetAccountForm);

class LoginPage extends Component {
    state = {
        passwordReset: false
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        let {userId} = this.form.getFieldsValue();

        try {
            this.props.onApiCallStarted();

            let loginResponse = await forgotPassword(userId);
            this.props.onApiCallFinished();

            this.setState({
                passwordReset: true
            })
        } catch (e) {
            this.props.onErrorReceived(e)
        }
    }

    setFormRef = (form) => {
        this.form = form;
    };

    renderContent = () => {
        if (this.state.passwordReset) {
            Modal.info({
                title: 'Snart klar',
                content: 'Nu ska du bara logga in på mailen och följa instruktionerna för att välja ett nytt lösenord.',
                okText: "Ok",
                onOk() {
                },
                onCancel() {
                },
            });

            return <div><FormattedHTMLMessage id="setYourPassword"/></div>
        } else {
            const {credentials} = this.props.auth;

            return <WrappedResetAccountForm ref={this.setFormRef} onSubmit={this.handleSubmit}
                                            credentials={credentials}/>
        }
    };

    render() {
        return (
            <UserFeedback>
                <Row>
                    <Col xs={8} className="mx-auto">
                        <Row className={styles.head}>
                            <ReactSVG src="/img/logotype.svg" className="m-auto"/>
                        </Row>

                        <Row className={styles.form}>
                            <Col xs={9} className="mx-auto">
                                <h1 className={styles.heading}>
                                    <FormattedHTMLMessage id="resetPassword"/>
                                </h1>
                                {this.renderContent()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </UserFeedback>
        );
    }
}

//Extract relevant keys from the store state, we are only interested in auth right now
const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToActions = {
    onLoginInitiated: loginInitiated,
    onLoginSuccessful: loginSuccessful,
    onErrorReceived: errorReceived,
    onApiCallStarted: apiCallStarted,
    onApiCallFinished: apiCallFinished
};

export default connect(mapStateToProps, mapDispatchToActions)(LoginPage);