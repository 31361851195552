import { USER_FEEDBACK_ACTION_TYPE } from "./../actions/constants";

const initialState = {
  callingApi: false,
  errors: null,
  isShowingNotification: false,
  dismissedNotification: false,
};

const userFeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_FEEDBACK_ACTION_TYPE.ERROR_RECEIVED: {
      const { exception } = action.payload;
      let errors;
      try {
        errors = JSON.parse(exception.message);
      } catch (e) {
        errors = { unknownField: "somethingWentWrong" };
      }
      return { ...state, errors, callingApi: false };
    }
    case USER_FEEDBACK_ACTION_TYPE.ERROR_CLEARED: {
      return { ...state, errors: null };
    }
    case USER_FEEDBACK_ACTION_TYPE.API_CALL_STARTED: {
      return { ...state, callingApi: true };
    }
    case USER_FEEDBACK_ACTION_TYPE.API_CALL_FINISHED: {
      //TODO: Probably remove error here
      return { ...state, callingApi: false };
    }
    case USER_FEEDBACK_ACTION_TYPE.SHOW_NOTIFICATION: {
      return { ...state, isShowingNotification: true };
    }
    case USER_FEEDBACK_ACTION_TYPE.HIDE_NOTIFICATION: {
      return {
        ...state,
        isShowingNotification: false,
        dismissedNotification: true,
      };
    }
  }
  return state;
};

export default userFeedbackReducer;
