import React from 'react';
import {Provider} from 'react-redux'
import {Route, Switch} from 'react-router';
import PrivateRoute from './components/private-route'
import SuppliersPage from './pages/suppliers';
import LoginPage from './pages/login';
import FinalizeAccountPage from './pages/finalize-account';
import CoworkersPage from './pages/coworkers';
import AssignmentsPage from './pages/assignments';
import CompanyPage from './pages/companies';
import AssignmentPassPage from './pages/assignment-pass';
import CheckingPage from './pages/checkin';
import ResetAccountPage from './pages/reset-account';
import SetPasswordPage from './pages/set-password';
import store from './store'

// <Layout>
// </Layout>
export default () => (
    <Provider store={store}>
        <Switch>
            <Route exact path='/' component={LoginPage}/>
            <Route exact path='/reset-account' component={ResetAccountPage}/>
            <Route exact path='/set-password/:code' component={SetPasswordPage}/>
            <Route exact path='/finalize-account/:finalizeAccountToken' component={FinalizeAccountPage}/>
            <Route exact path='/assignment-pass/:qRCodeData' component={AssignmentPassPage}/>
            <Route exact path='/checkin/:shopId/:qRCodeData' component={CheckingPage}/>
            <PrivateRoute path='/assignments' component={AssignmentsPage}/>
            <PrivateRoute path='/companies' component={CompanyPage}/>
            <PrivateRoute path='/coworkers' component={CoworkersPage}/>
            <PrivateRoute path='/suppliers' component={SuppliersPage}/>
        </Switch>
    </Provider>
);