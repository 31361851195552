import fetch from './../lib/fetch-client'

const request = fetch();

export function getShops() {
    return request({
        method: "GET",
        path: "shop",
    }).then(function ({status, headers, body}) {
        return body;
    });
}

export function getShopsForCompany(companyId) {
    let path = "shop/CurrentShopsForCompany?companyId="+companyId;
    return request({
        method: "GET",
        path: path ,
    }).then(function ({status, headers, body}) {
        return body;
    });
}